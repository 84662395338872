<template>
  <div>
    <div class="headerDetail">
      <ds-header :title="headerTitle"></ds-header>
      <a-button type="primary" @click="back">返回</a-button>
    </div>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-row>
        <a-col :span="span">
          <a-form-model-item label="标题" prop="title">
            <a-input v-model="form.title" :maxLength="20" placeholder="请输入标题(20字)" class="width400"
              :disabled="disabled" />
          </a-form-model-item>
        </a-col>
        <a-col :span="span" v-if="isKcjt">
          <a-form-model-item label="透出端口" prop="penetrate">
            <a-radio-group name="radioGroup" v-model="form.penetrate" :disabled="disabled">
              <a-radio :value="item.value" v-for="(item, index) in showPortOptions" :key="index"> {{ item.label }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="可见项目" prop="project_list">
            <el-cascader :getPopupContainer="(triggerNode) => triggerNode.parentNode" placeholder="请选择"
              v-model="form.project_list" :options="projectOptions" :props="fieldNames" clearable filterable size="mini"
              class="width400" :disabled="disabled">
            </el-cascader>
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="位置" prop="place_to_module">
            <a-radio-group name="radioGroup" v-model="form.place_to_module" :disabled="disabled">
              <a-radio :value="item.value" v-for="(item, index) in positionOptions" :key="index"> {{ item.label }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="新版封面" prop="image_url_u3">
            <a-upload list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
              :action="IMG_API + '/oss/files'" :before-upload="beforeUpload" @change="handleImgChange"
              accept=".png,.jpg,.jpeg,.gif" :disabled="disabled">
              <img :style="{ width: dialogImageUrlNewWidth * 0.2 + 'px', height: dialogImageUrlNewHeight * 0.2 + 'px' }"
                v-if="form.image_url_u3" :src="form.image_url_u3" alt="avatar" />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
              </div>

            </a-upload>
            <p style="line-height: 20px;">
              注:
              上传尺寸建议为<span v-show="dialogImageUrlNewHeight && dialogImageUrlNewWidth"
                style="color: red; padding-left: 10px">{{ dialogImageUrlNewWidth }}px * {{ dialogImageUrlNewHeight
                }}px</span>支持小于2M的jpg、png、jpeg格式的图片&支持小于10M的gif格式的动图
            </p>
          </a-form-model-item>
        </a-col>
        <!-- <a-col :span="span" v-else>
          <a-form-model-item label="老版封面" prop="image_url">
            <a-upload list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
              :action="IMG_API + '/oss/files'" :before-upload="beforeUploadOld" @change="handleImgChangeOld"
              accept=".png,.jpg,.jpeg,.gif" :disabled="disabled">
              <img style="width: 100px; height: 80px;" v-if="form.image_url" :src="form.image_url" alt="avatar" />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
              </div>

            </a-upload>
          </a-form-model-item>
        </a-col> -->
        <a-col :span="span">
          <a-form-model-item label="优先级" prop="priority">
            <a-input-number id="inputNumber" v-model="form.priority" :min="1" :max="999" class="width400"
              placeholder="请输入优先级" :disabled="disabled" />
            <p style="line-height: 20px;">取值范围1-999，数值越小优先级越高</p>
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="有效日期" prop="time">
            <a-range-picker :show-time="{ format: 'HH:mm:ss' }"
              :getCalendarContainer="(triggerNode) => triggerNode.parentNode" v-model="form.time"
              format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" class="width400" :disabled="disabled" />
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="类型" prop="type">
            <a-radio-group name="radioGroup" v-model="form.type" :disabled="disabled">
              <a-radio :value="item.value" v-for="(item, index) in typeOptions" :key="index"> {{ item.label }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="span" v-if="form.type === TypeEnum.LINK">
          <a-form-model-item label="落地页链接" prop="page_link">
            <a-input v-model="form.page_link" placeholder="请输入链接" class="width400" :disabled="disabled" />
          </a-form-model-item>
        </a-col>
        <a-col :span="span" v-else>
          <a-form-model-item label="自定义内容" prop="custom_content_text">
            <div class="custom_content_text">
              <div id="editer"></div>
            </div>
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="返回按钮">
            <a-radio-group name="radioGroup" v-model="form.show_back_btn" :disabled="disabled">
              <a-radio :value="item.value" v-for="(item, index) in showBackButtonOptions" :key="index"> {{ item.label }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="状态" prop="state">
            <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="form.state" class="width400"
              placeholder="请选择" :disabled="disabled">
              <a-select-option v-for="(item, index) in statusOptions" :key="index" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="是否支持分享" prop="share_state" v-if="form.penetrate === ShowPortEnum.APP">
            <a-radio-group name="radioGroup" v-model="form.share_state" :disabled="disabled">
              <a-radio :value="item.value" v-for="(item, index) in supportShareOptions" :key="index"> {{ item.label }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="form.penetrate === ShowPortEnum.APP && form.share_state === SupportShareEnum.YES">
        <a-col :span="span">
          <a-form-model-item label="分享图片" prop="share_image">
            <a-upload list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
              :action="IMG_API + '/oss/files'" :before-upload="beforeUploadShare" @change="handleImgChangeShare"
              accept=".png,.jpg,.jpeg" :disabled="disabled">
              <img style="width: 100px; height: 80px;" v-if="form.share_image" :src="form.share_image" alt="avatar" />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
              </div>

            </a-upload>
            <p style="line-height: 20px;">
              注: 上传尺寸建议为5:4 支持小于500KB的jpg、png、jpeg格式的图片
            </p>
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="分享标题" prop="share_title">
            <a-input v-model="form.share_title" :maxLength="20" placeholder="请输入分享标题(不超过20字)" class="width400"
              :disabled="disabled" />
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="分享副标题">
            <a-input v-model="form.share_subtitle" :maxLength="30" placeholder="请输入分享副标题(不超过30字)" class="width400"
              :disabled="disabled" />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="footer" v-if="!disabled">
      <a-button key="submit" style="margin-right: 30px" type="primary" @click="onSubmit">
        保存
      </a-button>
    </div>
  </div>
</template>

<script>
import help from '../help'
import { findCurrentUserAuthorityProjectListNew } from '@/api/common'
import { IMG_API } from "@/config";
import wangEditor from "wangeditor";
import * as api from '@/api/popularizeManagement/bannerManagement'
export default {
  watch: {
    'form.place_to_module': {
      handler(newVal) {
        // this.form.image_url_u3 = ''
      },
      immediate: true,
      deep: true
    },
    'form.type': {
      handler(newVal) {
        if (newVal === this.TypeEnum.CUSTOM) {
          this.$nextTick(() => {
            this.createEditor()
            this.editor.txt.html(this.form.custom_content_text)
          })
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    // 是否科创集团的项目，是隐藏透出端
    isKcjt() {
      return !window.location.href.includes('kcjt')
    },
    headerTitle() {
      let text = '';
      if (this.$route.query.type === 'add') {
        text = '新增';
      } else if (this.$route.query.type === 'edit') {
        text = '编辑';
      } else if (this.$route.query.type === 'look') {
        text = '查看';
      }
      return `${text}推广位`
    },
    dialogImageUrlNewWidth() {
      const { place_to_module } = this.form;
      if (place_to_module === 0) {
        return 750
      } else if (place_to_module === 2 || place_to_module === 3) {
        return 690
      } else {
        return 0
      }
    },
    dialogImageUrlNewHeight() {
      const { place_to_module } = this.form;
      if (place_to_module === 0) {
        return 510
      } else if (place_to_module === 2) {
        return 270
      } else if (place_to_module === 3) {
        return 200
      } else {
        return 0
      }
    },
    disabled() {
      return this.$route.query.type === 'look';
    }
  },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      span: 13,
      showPortOptions: help.showPortOptions,
      positionOptions: help.positionOptions,
      typeOptions: help.typeOptions,
      showBackButtonOptions: help.showBackButtonOptions,
      statusOptions: help.statusOptions,
      supportShareOptions: help.supportShareOptions,
      TypeEnum: help.TypeEnum,
      ShowPortEnum: help.ShowPortEnum,
      SupportShareEnum: help.SupportShareEnum,
      fieldNames: { multiple: true, expandTrigger: 'hover', checkStrictly: true, emitPath: false, ...help.projectOptionsFieldNames },
      projectOptions: [], // 项目列表选项
      loading: false,
      IMG_API,
      form: {
        title: '', // 标题
        penetrate: help.ShowPortEnum.ALL, // 透出端口
        project_list: [], // 可见项目
        place_to_module: help.PositionEnum.HOME_PAGE, // 位置
        image_url_u3: '', // 新封面图
        priority: undefined, // 优先级
        time: [], // 有效时间
        type: help.TypeEnum.LINK, // 类型
        page_link: '', // 落地页链接
        custom_content_text: '', // 自定义内容
        show_back_btn: help.ShowBackButtonEnum.HIDE, // 返回按钮
        state: undefined, // 状态
        share_state: help.SupportShareEnum.NO, // 是否支持分享
        share_image: '', // 分享图片
        share_title: '', // 分享标题
        share_subtitle: '', // 分享副标题
        image_url: '', // 老封面图
      },
      rules: {
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
        ],
        penetrate: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        project_list: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        image_url_u3: [
          { required: true, message: "请选择", trigger: ['blur', 'change'] },
        ],
        priority: [
          { required: true, message: "请输入优先级", trigger: 'blur' },
        ],
        time: [
          { required: true, message: "请选择", trigger: ['blur', 'change'] },
        ],
        type: [
          { required: true, message: "请选择", trigger: ['blur', 'change'] },
        ],
        page_link: [
          { required: true, message: "请输入链接", trigger: 'blur' },
        ],
        custom_content_text: [
          { required: true, message: "请输入", trigger: 'blur' },
        ],
        show_back_btn: [
          { required: true, message: "请选择", trigger: ['blur', 'change'] },
        ],
        state: [
          { required: true, message: "请选择", trigger: ['blur', 'change'] },
        ],
        share_state: [
          { required: true, message: "请选择", trigger: ['blur', 'change'] },
        ],
        share_image: [
          { required: true, message: "请选择", trigger: ['blur', 'change'] },
        ],
        share_title: [
          { required: true, message: "请输入", trigger: 'blur' },
        ]
      }
    };
  },
  methods: {
    /**数据处理 */
    // 处理提交数据
    submitDataDispose() {
      const submitForm = {
        ...this.form,
        start_time: this.form.time[0],
        end_time: this.form.time[1],
        image_url: this.form.image_url ? this.form.image_url : 'not_available',
        image_url_u3: this.form.image_url_u3 ? this.form.image_url_u3 : 'not_available',
        share_image: this.form.share_image ? this.form.share_image : 'not_available',
        show_title: 0,
        custom_content: []
      }
      return submitForm;
    },
    /**网络请求 */
    // 获取项目列表
    async getProjectList() {
      try {
        const res = await findCurrentUserAuthorityProjectListNew(true);
        if (res.code === this.$SUCCESS_CODE) {
          this.projectOptions = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 添加banner
    async addBanner() {
      try {
        const res = await api.addBanner(this.submitDataDispose())
        if (res.id) {
          this.$message.success('添加成功');
          this.back();
        } else {
          this.$message.warning('添加失败');
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 获取banner详情
    async getDetail() {
      try {
        const res = await api.getBannerDetail(this.$route.query.id);
        this.form = {
          ...res,
          time: [res.start_time, res.end_time],
          image_url: res.image_url && res.image_url !== 'not_available' ? res.image_url : '',
          image_url_u3: res.image_url_u3 && res.image_url_u3 !== 'not_available' ? res.image_url_u3 : '',
          share_image: res.share_image && res.share_image !== 'not_available' ? res.share_image : '',
        }
      } catch (error) {

      }
    },
    // 编辑banner
    async editBanner() {
      try {
        const res = await api.editBanner(this.submitDataDispose())
        if (res.id) {
          this.$message.success('编辑成功');
          this.back();
        } else {
          this.$message.warning('编辑成功');
        }
      } catch (error) {
        console.log(error);
      }
    },

    // 新版封面图片上传
    handleImgChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.loading = false;
        // this.params.serviceImage = info.file.response.redirect_uri;
        this.form.image_url_u3 = info.file.response.redirect_uri
      }
    },
    // 新版封面图片限制
    beforeUpload(file) {
      // 校验格式
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg" || file.type === 'image/gif';
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、png、gif格式");
        return Promise.reject();
      }
      // 校验大小
      const isLt2M = file.type === 'image/gif' ? file.size / 1024 / 1024 <= 10 : file.size / 1024 / 1024 <= 2;
      if (!isLt2M) {
        if (file.type === 'image/gif') {
          this.$message.error("git图最大10M");
        } else {
          this.$message.error("图片最大2M");
        }
        return Promise.reject();
      }
      let that = this
      // 校验比例
      const isSize = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const valid = (img.width === that.dialogImageUrlNewWidth && img.height === that.dialogImageUrlNewHeight)
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("图片不规范，请按要求上传");
          return Promise.reject();
        }
      );
      return isJpgOrPng && isLt2M && isSize;
    },

    // 老版封面图片上传
    handleImgChangeOld(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.loading = false;
        // this.params.serviceImage = info.file.response.redirect_uri;
        this.form.image_url = info.file.response.redirect_uri
      }
    },
    // 老版封面图片限制
    beforeUploadOld(file) {
      // 校验格式
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、png、gif格式");
        return Promise.reject();
      }

      return isJpgOrPng
    },
    // 分享图片限制
    beforeUploadShare(file) {
      // 校验格式
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、png格式");
        return Promise.reject();
      }
      // 校验大小
      const isLt2M = file.size / 1024 / 1024 <= 0.5;
      if (!isLt2M) {
        this.$message.error("图片最大500k");
        return Promise.reject();
      }
      return isJpgOrPng && isLt2M;
    },
    // 分享图片上传
    handleImgChangeShare(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.loading = false;
        // this.params.serviceImage = info.file.response.redirect_uri;
        this.form.share_image = info.file.response.redirect_uri
      }
    },
    // 创建富文本
    async createEditor() {
      const _this = this;
      const editor = new wangEditor(`#editer`);
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.form.custom_content_text = newHtml;
      };
      editor.config.menus = [
        "fontName",
        "fontSize",
        "foreColor",
        "backColor",
        "underline",
        "italic",
        "bold",
        "justify",
        "splitLine",
        "undo",
        "redo",
        "list",
        "table",
        "image",
        "video",
      ];
      editor.config.uploadVideoServer = this.IMG_API + "/oss/files";
      editor.config.uploadImgServer = this.IMG_API + "/oss/files";
      editor.config.uploadImgShowBase64 = false;
      editor.config.showLinkImg = false;
      editor.config.showLinkVideo = false;
      editor.config.uploadVideoMaxSize = 5 * 1024 * 1024;
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
      editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
      editor.config.uploadImgMaxLength = 1; // 一次最多上传 1 个图
      // editor.config.uploadVideoAccept = ['mp4']
      editor.config.uploadFileName = "file";
      editor.config.uploadVideoName = "file";
      editor.config.uploadVideoTimeout = 60000;
      editor.config.uploadImgTimeout = 60000;
      editor.config.zIndex = 99
      editor.config.uploadVideoHooks = {
        // 上传视频之前
        before: function (xhr, editor, files) {
          console.log(xhr);
          console.log(editor);
          console.log(files);
        },
        // 视频上传并返回了结果，视频插入已成功
        success: function (xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频超时
        timeout: function (xhr) {
          _this.$message.error("上传请求超时");
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function (insertVideoFn, result) {
          let videoHTML =
            '&nbsp;<video poster="' +
            result.redirect_uri +
            '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' +
            result.redirect_uri +
            '" controls style="max-width:100%"></video>&nbsp;';
          editor.cmd.do("insertHTML", videoHTML);
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          // insertVideoFn(result.redirect_uri)
        },
      };
      editor.config.uploadImgHooks = {
        // 上传视频之前
        before: function (xhr) { },
        // 视频上传并返回了结果，视频插入已成功
        success: function (xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频超时
        timeout: function (xhr) {
          _this.$message.error("上传请求超时");
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function (insertVideoFn, result) {
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          insertVideoFn(result.redirect_uri);
        },
      };
      editor.config.customAlert = function (s, t) {
        switch (t) {
          case "success":
            _this.$message.success(s);
            break;
          case "info":
            _this.$message.info(s);
            break;
          case "warning":
            _this.$message.warning(s);
            break;
          case "error":
            _this.$message.error(s);
            break;
          default:
            _this.$message.info(s);
            break;
        }
      };
      editor.config.placeholder = "请输入";
      // 创建编辑器
      editor.create();
      this.editor = editor;
      // 查看的时候禁用富文本
      if (this.$route.query.type === "look") {
        this.editor.disable();
      } else {
        this.editor.enable();
      }
    },
    back() {
      this.$router.back();
    },
    onSubmit() {
      console.log(this.form);
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.$route.query.type === 'add') {
            this.addBanner();
          } else {
            this.editBanner();
          }
        }
      });
    }
  },
  mounted() {
    if (this.$route.query.type !== 'add') {
      this.getDetail();
    }
  },
  created() {
    this.getProjectList();
  },
};
</script>

<style lang="scss" scoped>
.headerDetail {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.width400 {
  width: 400px !important;
}

.footer {
  margin-left: 200px;
  margin-top: 50px;
}
</style>
